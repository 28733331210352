import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ChakraProvider, Box, theme, IconButton, Flex } from "@chakra-ui/react";
import { ColorModeSwitcher } from "./components/ColorModeSwitcher";
import { AppMenu } from "./components/AppMenu";
import { CountBy } from "./components/CountBy";
import { AdditionProblem } from "./components/math/AdditionProblem";
import { SubtractionProblem } from "./components/math/SubtractionProblem";
import { MultiplicationProblem } from "./components/math/MultiplicationProblem";
import { DivisionProblem } from "./components/math/DivisionProblem";
import { StarSizeComparison } from "./components/star-size-comparison/StarSizeComparison";
import { SortedStars } from "./components/star-size-comparison/SortedStars";
import { StarFacts } from "./components/star-size-comparison/StarFacts";
import { Home } from "./components/Home";
import { FaHome } from "react-icons/fa";
import { SortedOrgans } from "./components/anatomy/SortedOrgans";
// import { FidgetGame } from "./components/fidget-game/FidgetGame";

function HomeButton() {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={() => navigate("/home")}
      aria-label="home"
      icon={<FaHome />}
      marginLeft={2}
    />
  );
}

const IS_HOME_ENABLED = false;

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" height={"100vh"}>
        <BrowserRouter>
          <Box position={"fixed"} top={2} left={2}>
            <AppMenu />
            {IS_HOME_ENABLED ? <HomeButton /> : null}
          </Box>
          <Box position={"fixed"} top={2} right={2}>
            <ColorModeSwitcher />
          </Box>
          <Routes>
            <Route path="count-by" element={<CountBy />} />
            <Route path="addition" element={<AdditionProblem />} />
            <Route path="subtraction" element={<SubtractionProblem />} />
            <Route path="multiplication" element={<MultiplicationProblem />} />
            <Route path="division" element={<DivisionProblem />} />
            <Route
              path="star-size-comparison"
              element={<StarSizeComparison />}
            />
            <Route path="stars-sorted-by-size" element={<SortedStars />} />
            <Route path="star-facts" element={<StarFacts />} />
            <Route path="organs-sorted-by-size" element={<SortedOrgans />} />
            <Route path="home" element={<Home />} />
            {/* <Route path="fidget-game" element={<FidgetGame />} /> */}
            <Route path="/" element={<Navigate to="home" replace />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
};
