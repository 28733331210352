import { useState } from "react";
import { Flex, Heading, Text, Card, CardBody } from "@chakra-ui/react";
import { HumanOrgan } from "./types";
import { useElementWidth } from "../useElementWidth";
import { CountBySwitch } from "../CountBy";
import { organs } from "./organs";
import { ScaleSlider } from "../ScaleSlider";

const sortedOrgans = organs.sort((organA, organB) => {
  const organASize = organA.sizeInCm.height * organA.sizeInCm.width;
  const organBSize = organB.sizeInCm.height * organB.sizeInCm.width;
  return organASize - organBSize;
});

const widestOrgan = sortedOrgans.reduce((prev, current) => {
  return prev.sizeInCm.width > current.sizeInCm.width ? prev : current;
});

const getImageTransform = (
  organ: HumanOrgan,
  scaleMultiplier: number
): string => {
  const x = organ.image?.translate?.x ?? 0;
  const y = organ.image?.translate?.y ?? 0;
  const scale = `scale(${organ.image?.scale ?? 1})`;
  const translate = `translate(${x * scaleMultiplier}px, ${
    y * scaleMultiplier
  }px)`;
  return `${scale} ${translate}`;
};

const Organ: React.FC<{
  organ: HumanOrgan;
  scaleMultiplier: number;
}> = ({ organ, scaleMultiplier }) => {
  const DEBUG = false;
  return (
    <Flex
      marginTop={8}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <div
        style={{
          backgroundColor: DEBUG || !organ.image ? "#D2704D" : undefined,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: scaleMultiplier * organ.sizeInCm.width,
          height: scaleMultiplier * organ.sizeInCm.height,
        }}
      >
        {organ.image ? (
          <img
            style={{
              objectFit: "contain",
              transformOrigin: "center",
              transform: getImageTransform(organ, scaleMultiplier),
            }}
            src={organ.image.src}
            alt={organ.name}
          />
        ) : null}
      </div>
      <Text fontSize={"2xl"}>{organ.name}</Text>
    </Flex>
  );
};

enum Orientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

const HumanReadableOrientationMap = {
  [Orientation.HORIZONTAL]: "Horizontal",
  [Orientation.VERTICAL]: "Vertical",
};

export const SortedOrgans: React.FC = () => {
  const [elementRef, width] = useElementWidth();
  const [scalePercent, setScalePercent] = useState(100);
  const [orientation, setOrientation] = useState(Orientation.VERTICAL);
  const maxWidth = width * (scalePercent / 100) - 20;
  const scaleMultiplier = maxWidth / widestOrgan.sizeInCm.width;

  return (
    <Flex
      ref={elementRef}
      flexDirection={"column"}
      p={5}
      overflowX={"hidden"}
      overflowY={"hidden"}
    >
      <Heading mb={5} fontSize={"4xl"}>
        Organs In Order
      </Heading>
      <Flex width="100%" justifyContent={"center"}>
        <Card flex={1} maxWidth={700}>
          <CardBody>
            <Flex flexDirection={"column"} paddingLeft={5} paddingRight={5}>
              <Text mb={5}>Organ Scale</Text>
              <ScaleSlider
                max={300}
                onChange={(percent) => setScalePercent(percent)}
              />
            </Flex>
            <Flex justifyContent={"space-evenly"}>
              <Flex flexDirection={"column"}>
                <Text mt={5}>Orientation</Text>
                <CountBySwitch
                  id="orientation"
                  label={HumanReadableOrientationMap[orientation]}
                  value={orientation === Orientation.HORIZONTAL}
                  onToggle={() =>
                    setOrientation((currentOrientation) =>
                      currentOrientation === Orientation.VERTICAL
                        ? Orientation.HORIZONTAL
                        : Orientation.VERTICAL
                    )
                  }
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        flexDirection={orientation === Orientation.VERTICAL ? "column" : "row"}
        flexWrap={orientation === Orientation.VERTICAL ? undefined : "wrap"}
        justifyContent={
          orientation === Orientation.VERTICAL ? undefined : "space-evenly"
        }
        alignItems={
          orientation === Orientation.VERTICAL ? undefined : "baseline"
        }
      >
        {sortedOrgans.map((organ) => (
          <Organ
            key={organ.name}
            organ={organ}
            scaleMultiplier={scaleMultiplier}
          />
        ))}
      </Flex>
    </Flex>
  );
};
