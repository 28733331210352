import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

interface ScaleSliderProps {
  onChange: (value: number) => void;
  max?: number;
}

export const ScaleSlider: React.FC<ScaleSliderProps> = ({
  onChange,
  max = 200,
}) => {
  return (
    <Slider
      flex={1}
      aria-label="step-slider"
      defaultValue={100}
      min={5}
      max={max}
      onChange={onChange}
      orientation="horizontal"
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={8} backgroundColor={"lightblue"} />
    </Slider>
  );
};
