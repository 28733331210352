import { HumanOrgan } from "./types"; // Assuming ./types defines the HumanOrgan interface { name: string; description: string; sizeInCm: { height: number; width: number }; }

export const organs: HumanOrgan[] = [
  {
    name: "Pineal Gland",
    description:
      "The pineal gland is a small endocrine gland in the brain. It produces melatonin. Located centrally in the epithalamus.",
    sizeInCm: {
      // Small, relatively symmetrical
      height: (0.5 + 0.8) / 2, // Average height = 0.65 cm
      width: 0.4, // Average width
    },
    image: {
      src: "images/anatomy/pineal-gland.png",
      scale: 1.8,
    },
  },
  {
    name: "Thyroid",
    description:
      "The thyroid is a butterfly-shaped endocrine gland in the neck. Produces thyroid hormones and calcitonin.",
    sizeInCm: {
      height: (4 + 6) / 2, // Average lobe height = 5 cm
      width: 5.5, // Overall gland width
    },
    image: {
      src: "images/anatomy/thyroid.png",
      scale: 1.15,
    },
  },
  {
    name: "Brain",
    description:
      "The control center of the nervous system, responsible for thought, memory, emotion, senses, and regulating bodily functions. Located within the skull.",
    sizeInCm: {
      height: 14, // Average transverse width
      width: (15 + 18) / 2, // Average A-P length = 16.5 cm
    },
    image: {
      src: "images/anatomy/brain.png",
      scale: 1.1,
    },
  },
  {
    name: "Heart",
    description:
      "A muscular organ in the chest that pumps blood through the circulatory system.",
    sizeInCm: {
      height: (11 + 14) / 2, // Average height (base to apex) = 12.5 cm
      width: 8.5, // Average width
    },
    image: {
      src: "images/anatomy/heart.png",
      scale: 1.5,
    },
  },
  {
    name: "Lungs", // Represents the pair, size is per lung
    description:
      "A pair of spongy, air-filled organs in the chest for gas exchange.",
    sizeInCm: {
      height: (24 + 28) / 2, // Average height = 26 cm
      width: 13, // Average width at base
    },
    image: {
      src: "images/anatomy/lung.png",
      scale: 2,
      translate: {
        x: -1,
      },
    },
  },
  {
    name: "Liver",
    description:
      "Largest solid internal organ in the upper right abdomen. Functions include detoxification, bile production, protein synthesis.",
    sizeInCm: {
      height: 16, // Approximate average height
      width: (15 + 23) / 2, // Average transverse width = 19 cm
    },
    image: {
      src: "images/anatomy/liver.png",
      scale: 1.1,
    },
  },
  {
    name: "Kidneys", // Represents the pair, size is per kidney
    description:
      "Pair of bean-shaped organs filtering waste from blood to produce urine.",
    sizeInCm: {
      height: (10 + 13) / 2, // Average height/length = 11.5 cm
      width: 6, // Average width
    },
    image: {
      src: "images/anatomy/kidney.png",
      scale: 1.75,
      translate: {
        x: 0.5,
      },
    },
  },
  {
    name: "Stomach",
    description:
      "J-shaped muscular organ in the upper abdomen that digests food.",
    sizeInCm: {
      // J-shape makes height/width approximate along main axes
      height: (20 + 30) / 2, // Average length along curve = 25 cm
      width: 12, // Approximate average width
    },
    image: {
      src: "images/anatomy/stomach.png",
      scale: 1.8,
    },
  },
  {
    name: "Small Intestine",
    description: "Long coiled tube for nutrient digestion and absorption.",
    sizeInCm: {
      // Note: 'height' represents total uncoiled length, 'width' is diameter.
      height: (550 + 750) / 2, // Average total length = 650 cm
      width: 2.75, // Approximate average diameter
    },
  },
  {
    name: "Large Intestine",
    description: "Final digestive section, absorbs water and forms feces.",
    sizeInCm: {
      // Note: 'height' represents total uncoiled length, 'width' is diameter.
      height: (140 + 160) / 2, // Average total length = 150 cm
      width: 6, // Approximate average diameter
    },
  },
  {
    name: "Skin",
    description:
      "Largest organ by surface area (average ~1.75 m²). Outer protective layer.",
    sizeInCm: {
      // Note: Height/Width represent side length of a square with equivalent average surface area.
      // height: 132.3, // Side length sqrt(17500 cm^2)
      // width: 132.3, // Side length sqrt(17500 cm^2)
      // height: 875,
      // width: 20,
      height: 700,
      width: 25,
    },
  },
  {
    name: "Pancreas",
    description:
      "Located behind the stomach, produces digestive enzymes and hormones like insulin.",
    sizeInCm: {
      // Pancreas lies transversely (side-to-side)
      // height: 4, // Approximate average height/thickness
      height: 6, // adjust for rotation of image
      width: (12 + 18) / 2, // Average transverse length = 15 cm (assigned to width)
    },
    image: {
      src: "images/anatomy/pancreas.png",
      scale: 1.1,
      translate: {
        y: -1,
      },
    },
  },
  {
    name: "Spleen",
    description:
      "Located in the upper left abdomen. Filters blood, part of the lymphatic system.",
    sizeInCm: {
      height: (7 + 14) / 2, // Average length (often superior-inferior axis) = 10.5 cm
      width: 7, // Approximate average width
    },
    image: {
      src: "images/anatomy/spleen.png",
      scale: 1.5,
    },
  },
  {
    name: "Urinary Bladder",
    description: "Muscular organ in the pelvic cavity that stores urine.",
    sizeInCm: {
      // Roughly spherical when full, variable. Using average dimension.
      height: (5 + 15) / 2, // Average dimension = 10 cm
      width: 10, // Average dimension = 10 cm
    },
    image: {
      src: "images/anatomy/urinary-bladder.png",
      scale: 1.3,
      translate: {
        y: -0.8,
      },
    },
  },
  {
    name: "Gallbladder",
    description:
      "Small organ beneath the liver that stores and concentrates bile produced by the liver.",
    sizeInCm: {
      // height: (7 + 10) / 2, // Average length = 8.5 cm
      height: 14, // adjust for non-bladder elements
      width: 4, // Average diameter when full
    },
    image: {
      src: "images/anatomy/gallbladder.png",
      scale: 3,
      translate: {
        x: 0.5,
        y: -0.5,
      },
    },
  },
  {
    name: "Larynx",
    description:
      "Also known as the voice box, located in the neck. Involved in breathing, sound production, and protecting the trachea.",
    sizeInCm: {
      // Varies significantly by sex and age. Using average adult male size.
      height: 4.4, // Average height
      width: 3.6, // Average transverse width
    },
    image: {
      src: "images/anatomy/larynx.png",
      scale: 1.5,
    },
  },
  {
    name: "Adrenal Glands", // Represents pair, size per gland
    description:
      "Pair of endocrine glands located on top of the kidneys. Produce hormones like cortisol and adrenaline.",
    sizeInCm: {
      // Roughly triangular/crescent shape
      height: 5, // Approximate average height
      width: 3, // Approximate average width/thickness
    },
    image: {
      src: "images/anatomy/adrenal-gland.png",
      scale: 2,
      translate: {},
    },
  },
  {
    name: "Diaphragm",
    description:
      "Large, dome-shaped muscle located at the base of the chest cavity that separates the abdomen from the chest. Primary muscle used in respiration.",
    sizeInCm: {
      // Dome shape is complex. 'Width' represents approximate transverse diameter.
      // 'Height' now represents approximate physical vertical dimension of the dome.
      // height: 11, // Approximate physical vertical dimension = 11 cm
      height: 20, // adjust for dome in image
      // width: (35 + 45) / 2, // Approximate average transverse diameter = 40 cm
      width: 30, // Approximate average transverse diameter = 40 cm
    },
    image: {
      src: "images/anatomy/diaphragm.png",
      scale: 1.2,
      translate: {
        y: -0.5,
      },
    },
  },
  {
    name: "Eyeball",
    description:
      "The organ of sight, located within the orbit. Detects light and converts it into electrochemical impulses in neurons.",
    sizeInCm: {
      // Approximately spherical
      height: 2.4, // Average axial length (front to back)
      width: 2.4, // Average transverse diameter
    },
    image: {
      src: "images/anatomy/eye.png",
      scale: 1.4,
    },
    // image field would be added here if available
  },
  {
    name: "Esophagus",
    description:
      "A muscular tube connecting the pharynx (throat) with the stomach. Transports food via peristalsis.",
    sizeInCm: {
      // Primarily a tube
      height: (23 + 27) / 2, // Average length = 25 cm
      width: 2, // Average diameter (can distend)
    },
    // image field would be added here if available
  },
  {
    name: "Hypothalamus",
    description:
      "A small region located at the base of the brain, below the thalamus. Controls body temperature, hunger, thirst, fatigue, sleep, and circadian rhythms.",
    sizeInCm: {
      // Very small, roughly almond-sized
      height: 1.0, // Approximate dimension along one axis
      width: 1.3, // Approximate dimension along another axis
    },
    image: {
      src: "images/anatomy/hypothalamus.png",
      scale: 1.8,
      translate: {
        x: 0.05,
        y: 0.15,
      },
    },
  },
  {
    name: "Lymph Node", // Represents a typical, single node
    description:
      "Small, bean-shaped organs acting as filters for the lymphatic system. Part of the immune system, trapping pathogens and housing lymphocytes.",
    sizeInCm: {
      // Size is highly variable, this represents a typical non-enlarged node
      height: 1.0, // Approximate length (longest dimension)
      width: 0.5, // Approximate width/thickness
    },
    image: {
      src: "images/anatomy/lymph-node.png",
      scale: 3,
      translate: {
        x: 0.07,
      },
    },
  },
];
